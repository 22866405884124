import React from 'react';

const Hero = () => {
  return (
    <section
      className="hero is-inner"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/hero-instituicoes-bg.png')})` }}>
      <div className="hero-body">
        <div className="container">
          <span className="title is-size-2 is-white is-700 visible-desktop-false">
            Instituições participantes
          </span>
        </div>
      </div>
    </section> 
  );
};

export default Hero;