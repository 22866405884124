import React from 'react';

const Filter = ({ onChange, filter }) => {

  const regions = [
    'Todas as regiões',
    'Norte',
    'Nordeste',
    'Centro-oeste',
    'Sudeste',
    'Sul'
  ].map((item, index) => (
    <label className="toggle-button" key={index}>
      <input
        type="checkbox"
        name="region"
        checked={filter['regions'][item]}
        onChange={({ target }) => onChange('regions', item, target.checked)}
      />
      <span className="toggle-button-element">{item}</span>
    </label>
  ));

  const causes = [
    'Todas as causas',
    'Cultura e esporte',
    'Educação básica',
    'Inclusão e acessibilidade',
    'Assistência social',
    'Saúde e nutrição'    
  ].map((item, index) => (
    <label className="toggle-button" key={index}>
      <input
        type="checkbox"
        name="cause"
        checked={filter['causes'][item]}
        onChange={({ target }) => onChange('causes', item, target.checked)}
      />
      <span className="toggle-button-element">{item}</span>
    </label>
  ));

  // const checkRegionsFilter = () => {
  //   const keys = Object.keys(filter['regions']);
  //   return keys.filter((itemKey) => {
  //     const value = filter['regions'][itemKey];
  //     return value && itemKey !== 'Todas as regiões';
  //   });
  // }

  // console.log(checkRegionsFilter())

  return (
    <>
      <div className="filter">
        <div className="filter-body">          
          <h2 className="title is-size-2">Filtrar por</h2>
          <div className="v-space-lg"></div>
          <h3 className="title is-size-4 is-400">Regiões do Brasil</h3>
          <div className="v-space" />
          <div className="toggle-button-group">{regions}</div>
          <div className="v-space" />
          <h3 className="title is-size-4 is-400">Causas</h3>
          <div className="v-space" />
          <div className="toggle-button-group">{causes}</div>
        </div>
      </div>
      <div className="v-space-lg" />
    </>
  );
};

export default Filter;